<template>
  <div class="user-wrap">
    <div class="user-left">
      <div class="user-title">My Account</div>
      <div class="user-info">
        <div class="ui-face">
          <img
            :src="
              user.profilePath == 'defaultPath'
                ? require('../assets/images/img-face.png')
                : 'data:image/png;base64,' + avatar
            "
          />
        </div>
        <div class="ui-name">{{ user.uname }}</div>
        <div class="ui-integral">
          <font-awesome-icon
            icon="coins"
            style="color: #090970; font-size: 18px; margin-right: 8px"
          />
          {{ user.points }}
        </div>
      </div>
      <div class="user-nav">
        <li>
          <router-link
            :to="{ path: '/account/settings', query: { uid: user.uid } }"
            ><div :class="{ active: sideNav[0] }">
              Account Settings
            </div></router-link
          >
        </li>
        <li>
          <router-link
            :to="{ path: '/account/usermessage', query: { uid: user.uid } }"
            ><div :class="{ active: sideNav[1] }">
              User Message
            </div></router-link
          >
        </li>
        <li>
          <router-link
            :to="{ path: '/account/articles', query: { uid: user.uid } }"
            ><div :class="{ active: sideNav[2] }">
              Proposed Articles
            </div></router-link
          >
        </li>
        <li>
          <router-link
            :to="{ path: '/account/comments', query: { uid: user.uid } }"
            ><div :class="{ active: sideNav[3] }">
              Proposed Comments
            </div></router-link
          >
        </li>
        <li>
          <router-link
            :to="{ path: '/account/moder', query: { uid: user.uid } }"
            ><div :class="{ active: sideNav[4] }">
              Moderation Progress
            </div></router-link
          >
        </li>
        <li>
          <router-link
            :to="{ path: '/account/points', query: { uid: user.uid } }"
            ><div :class="{ active: sideNav[5] }">
              Points History
            </div></router-link
          >
        </li>
        <li>
          <router-link
            :to="{ path: '/account/reading', query: { uid: user.uid } }"
            ><div :class="{ active: sideNav[6] }">
              Activity History
            </div></router-link
          >
        </li>
        <li>
          <router-link
            :to="{ path: '/account/datamanagement', query: { uid: user.uid } }"
            ><div :class="{ active: sideNav[7] }">
              Data Management
            </div></router-link
          >
        </li>
      </div>
    </div>
    <div class="user-right">
      <router-view />
    </div>
  </div>
</template>

<script>
import { onUpdated, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { post, get } from "@/api";
import { ElLoading } from "element-plus";

export default {
  name: "Account",
  setup() {
    const avatar = ref("");
    const sideNav = ref([false, false, false, false, false, false, false, false]);
    const user = ref({});
    const route = useRoute();
    checkRoute(route.name);
    watch(
      () => route.name,
      (to) => {
        checkRoute(to);
      }
    );
    
    function checkRoute(route) {
      switch (route) {
        case "AccountSettings":
          sideNav.value = [true, false, false, false, false, false, false, false];
          break;
        case "UserMessage":
          sideNav.value = [false, true, false, false, false, false, false, false];
          break;
        case "ProposedArticles":
          sideNav.value = [false, false, true, false, false, false, false, false];
          break;
        case "ProposedComments":
          sideNav.value = [false, false, false, true, false, false, false, false];
          break;
        case "ModerationProgress":
          sideNav.value = [false, false, false, false, true, false, false, false];
          break;
        case "PointsHistory":
          sideNav.value = [false, false, false, false, false, true, false, false];
          break;
        case "ReadingHistory":
          sideNav.value = [false, false, false, false, false, false, true, false];
          break;
        case "DataManagement":
          sideNav.value = [false, false, false, false, false, false, false, true];
          break;
      }
    }

    function checkLogin() {
      if (sessionStorage.getItem("token")) {
        post(`/user/logged`).then((res) => {
          if (res.res == 1) {
            user.value = res.user;
            if (res.user.profilePath != "defaultPath") {
              const loading = ElLoading.service({
                lock: true,
                text: "Loading",
                background: "rgba(255, 255, 255, 0.97)",
              });
              get("/file/download", { fileName: user.value.profilePath }).then(
                (res) => {
                  avatar.value = res.data;
                  loading.close();
                }
              );
            }
          } else {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem('Authorization');
          }
        });
      }
    }
    checkLogin();
    onUpdated(() => {});
    return {
      sideNav,
      user,
      avatar,
    };
  },
};
</script>

<style scoped>
.user-wrap {
  display: flex;
  width: 1240px;
  margin: 25px auto 45px auto;
  justify-content: space-between;
}
.user-left {
  flex: 0 0 240px;
  width: 240px;
  min-height: 580px;
  border-radius: 30px;
  background-color: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
}
.user-right {
  flex: 0 0 965px;
  width: 965px;
  min-height: 580px;
  border-radius: 30px;
  background-color: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  /*border: 1px solid red;*/
}
.user-title {
  display: block;
  margin-top: 40px;
  width: 100%;
  color: #111880;
  text-align: center;
  font-size: 20px;
  font-family: "Poppins-ExtraBold";
}
.user-info {
  display: block;
  width: 100%;
  margin-top: 22px;
  text-align: center;
}
.user-info .ui-face {
  flex: 0 0 128px;
  display: block;
  margin: 0 auto;
  width: 128px;
  height: 128px;
}
.user-info .ui-face img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid #5473ff;
}
.user-info .ui-name {
  font-size: 20px;
  font-family: "Poppins-SemiBold";
  margin-bottom: 6px;
  color: #111880;
  margin-top: 20px;
}
.user-info .ui-integral {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 16px;
  color: #5473ff;
  justify-content: center;
}
.user-info .ui-integral img {
  width: 19px;
  height: 19px;
  margin-right: 8px;
}
.user-nav {
  display: block;
  width: 100%;
  margin-top: 26px;
}
.user-nav li {
  display: block;
  width: 100%;
  margin: 10px 0;
}
.user-nav li div {
  display: block;
  width: 100%;
  font-size: 16px;
  height: 55px;
  line-height: 55px;
  color: #111880;
  font-family: "Poppins-SemiBold";
  background-color: #ffffff;
  text-align: center;
  border-radius: 30px;
}
.user-nav li.cur div,
.user-nav li:hover div {
  color: #ffffff;
  background-color: #5473ff;
}
.user-nav .active {
  color: #ffffff;
  background-color: #5473ff;
}
</style>
